import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient";
import { PortableText } from "@portabletext/react";

function Breaking() {
  const [news, setNews] = useState();

  useEffect(() => {
    getNews();
  }, []);

  const getNews = () => {
    sanityClient
      .fetch(
        `*[_type == "breaking"]{
            title,
            description,
            "imageUrl": image.asset->url
        }`
      )
      .then((data) => {
        if (data !== null) {
          setNews(data[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const newsDescription = {
    block: {
      normal: ({ children, value }) => (
        <p className="text-green py-2 break-words">{children}</p>
      ),
    },
    marks: {
      link: ({ children, value }) => {
        const rel = !value.href.startsWith("/")
          ? "noreferrer noopener"
          : undefined;
        return (
          <a
            className="text-green underline"
            href={value.href}
            rel={rel}
            target="_blank"
          >
            {children}
          </a>
        );
      },
    },
  };

  if (news !== undefined) {
    return (
      <section id="news" className="md:min-h-screen bg-rose w-full">
        <div className="flex h-full flex-col md:flex-row md:min-h-screen items-center">
          <div className="w-full h-full md:w-5/12 md:min-h-screen">
            <img
              className="object-cover hidden md:block w-full md:min-h-screen h-full"
              src={news.imageUrl}
            />
          </div>
          <div className="md:w-7/12 h-full p-10 lg:p-20 md:min-h-screen">
            <h2 className="text-green">{news.title}</h2>
            <PortableText
              value={news.description}
              components={newsDescription}
            />
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
}

export default Breaking;
