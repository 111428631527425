import React, { useEffect } from "react";
import "./App.css";
import { useLocation, BrowserRouter, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Onepage from "./pages/Onepage";
import AnimatedRoutes from "./components/AnimatedRoutes";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
}

export default App;
