import React, { useEffect, useRef, useState } from "react";
import sanityClient from "../sanityClient";
import { SwiperSlide, Swiper } from "swiper/react";
import ArrowTop from "../assets/images/arrow-top.svg";
import ArrowBottom from "../assets/images/arrow-bottom.svg";
import { PortableText } from "@portabletext/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Scrollbar, Navigation } from "swiper";

const Agenda = () => {
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(0);
  const today = new Date();
  const swiperRef = useRef(null); // Stocke l'instance de Swiper

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "agenda"]{
            title,
          body,
          date
        } | order(date asc)`
      )
      .then((data) => {
        setEvents(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (events.length > 0) {
      findNextEvent();
    }
  }, [events]);

  const findNextEvent = () => {
    var nextEvent = events.length - 1;
    for (var i = 0; i < events.length; i++) {
      if (today < new Date(events[i].date)) {
        nextEvent = i;
        break;
      }
    }
    return nextEvent;
  };

  const eventDescription = {
    block: {
      normal: ({ children, value }) => (
        <p className="text-white p-4">{children}</p>
      ),
    },
    marks: {
      link: ({ children, value }) => {
        const rel = !value.href.startsWith("/")
          ? "noreferrer noopener"
          : undefined;
        return (
          <a
            className="text-white underline"
            href={value.href}
            rel={rel}
            target="_blank"
          >
            {children}
          </a>
        );
      },
    },
  };

  const getEventView = () => {
    var bgEvent = "bg-grey-dark";
    return events.map((item, i) => {
      var eventDate = new Date(item.date);
      if (eventDate >= today) {
        bgEvent = "bg-white-rose";
      }
      return (
        <SwiperSlide
          key={i}
          className="cursor-pointer flex flex-col justify-center"
        >
          <div className="px-4">
            <a
              className={`flex flex-row justify-between items-center h-[100px]`}
              onClick={() => setCurrentEvent(i)}
            >
              <span className={`w-4 h-4 mr-2 rounded-[50%] ${bgEvent}`}></span>
              <p className="text-green mr-2">{item.title}</p>
              <p className="text-green opacity-40">
                {eventDate.toLocaleDateString()}
              </p>
            </a>
          </div>
        </SwiperSlide>
      );
    });
  };

  const getDateFormat = (date) => {
    var date_array = date.split("-");
    return date_array[2] + "/" + date_array[1] + "/\n" + date_array[0];
  };

  if (events.length > 0) {
    return (
      <section id="agenda" className="h-full bg-green">
        <div className="md:pl-[10%]">
          <h2 className="text-grey col-span-8 p-10 text-[60px]">
            Evénements à venir
          </h2>
          <div className="grid grid-cols-12 lg:h-2/3 flex flex-col">
            <div className="col-span-12 md:col-start-1 md:col-end-8">
              <div className="agenda-swiper bg-[#D7DEDB] rounded-tr-[5em] md:rounded-tr-[0%] md:rounded-tl-[5em] overflow-hidden lg:h-3/4 px-[2em] lg:px-10">
                <Swiper
                  className="flex flex-col justify-center h-full w-full"
                  direction="vertical"
                  modules={[Navigation, Scrollbar]}
                  slidesPerView="auto"
                  initialSlide={findNextEvent()}
                  centeredSlides={true}
                  slideToClickedSlide={true}
                  onSwiper={(swiper) => (swiperRef.current = swiper)}
                  onSlideChange={(swiper) =>
                    setCurrentEvent(swiper.activeIndex)
                  }
                >
                  <div class="swiper-wrapper w-full h-full">
                    {getEventView()}
                  </div>
                  <span className="absolute w-2 h-full top-0 m-auto left-4 timeline"></span>
                  <div className="absolute rounded-[60px] top-0 bg-grey-dark h-[80px] md:h-[100px] w-full bottom-0 m-auto"></div>
                  <div className="absolute bottom-0 w-full bg-gradient-to-t from-grey h-[20%] left-0 z-50"></div>
                  <div className="absolute top-0 w-full bg-gradient-to-b from-grey h-[20%] left-0 z-50"></div>
                </Swiper>
              </div>
              <div className="bg-white flex flex-row lg:h-1/4">
                <div className="w-1/2 h-full flex items-center justify-center bg-grey-dark">
                  <a
                    onClick={() => swiperRef.current?.slidePrev()}
                    className="cursor-pointer"
                  >
                    <img src={ArrowTop} />
                  </a>
                </div>
                <div className="w-1/2 h-full flex items-center justify-center bg-grey">
                  <a
                    onClick={() => swiperRef.current?.slideNext()}
                    className="cursor-pointer"
                  >
                    <img src={ArrowBottom} />
                  </a>
                </div>
              </div>
              <div className="w-full lg:hidden">
                <div className="bg-[#F7E1DA] h-2/4 flex flex-col justify-center lg:justify-end p-4">
                  <p className="text-[30px] leading-[36px] lg:text-[120px] text-green lg:leading-[130px]">
                    {getDateFormat(events[currentEvent].date)}
                  </p>
                  <p className="text-[20px] leading-[24px] lg:text-[50px] lg:leading-[60px] text-green">
                    {events[currentEvent].title}
                  </p>
                </div>
                <div className="bg-green border-b-2 border-b-[#D7DEDB] flex h-2/4">
                  <PortableText
                    value={events[currentEvent].body}
                    components={eventDescription}
                  />
                </div>
              </div>
            </div>
            <div className="hidden lg:block col-span-12 md:col-start-8 md:col-span-5 lg:mt-0">
              <div className="bg-[#F7E1DA] h-3/4 flex flex-col justify-center lg:justify-end p-4">
                <p className="text-[30px] leading-[36px] lg:text-[120px] text-green lg:leading-[130px]">
                  {getDateFormat(events[currentEvent].date)}
                </p>
                <p className="text-[20px] leading-[24px] lg:text-[50px] lg:leading-[60px] text-green">
                  {events[currentEvent].title}
                </p>
              </div>
              <div className="bg-green border-b-2 border-b-[#D7DEDB] flex flex-col h-full lg:h-1/4">
                <PortableText
                  value={events[currentEvent].body}
                  components={eventDescription}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
};

export default Agenda;
