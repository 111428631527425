import React, { useEffect, useState } from "react";
import ButtonSlide from "./buttonSlide";
import ErrorImg from "../assets/images/error.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { init } from "@emailjs/browser";
import emailjs from "@emailjs/browser";

init("user_ebGd3uREMi6nnb8NlwCi5");

function Contact() {
  const [member, setMember] = useState(false);
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [object, setObject] = useState("");

  const [toastMessage, setToastMessage] = useState("");

  const recaptchaRef = React.createRef();

  const validateEmail = () => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validateForm = () => {
    if (
      name.length === 0 ||
      firstname.length === 0 ||
      object.length === 0 ||
      !validateEmail()
    ) {
      setError(true);
      setToastMessage("Veuillez compléter les champs correctement.");
      return false;
    }
    return true;
  };

  const sendEmail = async () => {
    const token = await recaptchaRef.current.executeAsync();
    var templateParams = {
      from_name: firstname + " " + name,
      object: object,
      message: message,
      reply_to: email,
      "g-recaptcha-response": token,
    };

    await emailjs
      .send("service_bu7hvf8", "template_qg14gui", templateParams)
      .then((res) => {
        console.log("result sent", res);
        return true;
      })
      .catch((err) => {
        console.log("error sent", err);
        setToastMessage("Veuillez réessayer ultérieurement.");
        return false;
      });
  };

  const getToastMessage = () => {
    setTimeout(() => {
      setToastMessage("");
    }, 3000);
    if (toastMessage.length > 0) {
      return (
        <div className="fixed z-50 text-[12px] md:text-[20px] bottom-10 right-0 left-0 m-auto bg-white w-10/12 lg:w-6/12 flex justify-center rounded-[50px] p-2">
          <p>{toastMessage}</p>
        </div>
      );
    }
  };

  return (
    <section
      id="contact"
      className="h-full bg-green p-[5%] pb-20 lg:p-[10%] relative"
    >
      <div className="grid grid-cols-12">
        <div className="flex flex-col my-6 col-span-11 md:col-start-1 md:col-end-5">
          <label className="text-white-rose opacity-30">Nom*</label>
          <div className="relative w-full flex flex-row items-center">
            {error && name.length === 0 ? (
              <img
                className="absolute right-0 z-10 m-auto p-[6px] mr-2 bg-green rounded-full  w-[20px] h-[20px] md:w-[26px] md:h-[26px]"
                src={ErrorImg}
              />
            ) : null}
            <input
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              placeholder="Votre nom"
              className={`${
                error && name.length === 0
                  ? "bg-white-rose-400"
                  : "bg-transparent"
              } border-b-[3px] border-white-rose focus:outline-none text-white-rose w-full`}
            />
          </div>
        </div>

        <div className="flex flex-col my-6 col-span-11 md:col-start-7 md:col-end-12">
          <label className="text-white-rose opacity-30">Prénom*</label>
          <div className="relative w-full flex flex-row items-center">
            {error && firstname.length === 0 ? (
              <img
                className="absolute right-0 z-10 m-auto p-[6px] mr-2 bg-green rounded-full  w-[20px] h-[20px] md:w-[26px] md:h-[26px]"
                src={ErrorImg}
              />
            ) : null}
            <input
              value={firstname}
              onChange={(e) => setFirstname(e.currentTarget.value)}
              placeholder="Votre prénom"
              className={`${
                error && firstname.length === 0
                  ? "bg-white-rose-400"
                  : "bg-transparent"
              } border-b-[3px] border-white-rose focus:outline-none text-white-rose w-full`}
            />
          </div>
        </div>

        <div className="flex flex-col my-6 col-span-11 md:col-start-1 md:col-end-5">
          <label className="text-white-rose opacity-30">Email*</label>
          <div className="relative w-full flex flex-row items-center">
            {error && !validateEmail() ? (
              <img
                className="absolute right-0 z-10 m-auto p-[6px] mr-2 bg-green rounded-full w-[20px] h-[20px] md:w-[26px] md:h-[26px]"
                src={ErrorImg}
              />
            ) : null}
            <input
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              placeholder="Votre email"
              className={`${
                error && !validateEmail()
                  ? "bg-white-rose-400"
                  : "bg-transparent"
              } border-b-[3px] border-white-rose focus:outline-none text-white-rose w-full`}
            />
          </div>
        </div>

        <div className="flex flex-col my-6 col-span-11 md:col-start-7 md:col-end-12">
          <label className="text-white-rose opacity-30">Objet*</label>
          <div className="relative w-full flex flex-row items-center">
            {error && object.length === 0 ? (
              <img
                className="absolute right-0 z-10 m-auto p-[6px] mr-2 bg-green rounded-full  w-[20px] h-[20px] md:w-[26px] md:h-[26px]"
                src={ErrorImg}
              />
            ) : null}
            <input
              value={object}
              onChange={(e) => setObject(e.currentTarget.value)}
              placeholder="Votre objet"
              className={`${
                error && object.length === 0
                  ? "bg-white-rose-400"
                  : "bg-transparent"
              } border-b-[3px] border-white-rose focus:outline-none text-white-rose w-full`}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="flex flex-col my-6 col-start-1 col-end-12">
          <label className="text-white-rose opacity-30">Message*</label>
          <div className="relative w-full flex flex-row items-center">
            {error && message.length === 0 ? (
              <img
                className="absolute right-0 z-10 m-auto p-[6px] mr-2 bg-green rounded-full  w-[20px] h-[20px] md:w-[26px] md:h-[26px]"
                src={ErrorImg}
              />
            ) : null}
            <textarea
              value={message}
              onChange={(e) => setMessage(e.currentTarget.value)}
              placeholder="Votre message"
              className={`${
                error && message.length === 0
                  ? "bg-white-rose-400"
                  : "bg-transparent"
              } border-[3px] h-[400px] border-white-rose focus:outline-none text-white-rose w-full p-4`}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12">
        <div className="flex flex-col lg:flex-row lg:items-center justify-between w-full col-start-1 col-end-12">
          <div className="flex flex-row items-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://reservation.tcdomdidier.ch/user/signup"
              onClick={() => setMember(!member)}
              className="text-white-rose ml-4 cursor-pointer hover:underline"
            >
              Je veux devenir membre
            </a>
          </div>
          <ButtonSlide validate={validateForm} send={sendEmail} />
        </div>
      </div>
      {getToastMessage()}
      <ReCAPTCHA
        className="hidden"
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LeOX_4qAAAAAELV9uCVy4Be17KyNqSCEJGVzb0b"
      />
    </section>
  );
}

export default Contact;
